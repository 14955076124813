import React from 'react';

const PageHeader = (props) => (
  <section>
    <div id="intro" className={`${props.imageClass} header-image`}>
      <div>
        {props.title && (
          <div className="header-wrapper">
            <h1>{props.title}</h1>
          </div>
        )}
        {props.button && <div className="button-wrapper">{props.button}</div>}
      </div>
    </div>
  </section>
);

export default PageHeader;
